import React from 'react';
import PageLayout from '@global/page-layout';
import '@assets/styles/tailwind.css';
import {
  StyledContainer,
  StyledSection,
  Heading,
  SubHeading,
  Text,
  Button,
} from '@styled/components';
import styled from 'styled-components';
import { XCircle } from '@styled-icons/boxicons-regular/XCircle';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import * as typeformEmbed from '@typeform/embed';
import { IntercomAPI } from 'react-intercom';

const GridContainer = styled.nav.attrs({
  className:
    'flex flex-col sm:items-start sm:text-left text-center items-center mb-10',
})``;

const gridItems = [
  'no easy way to track international dispatches and delivery',
  'frequent product returns due to wrong shipments',
  'heavy use of excel to coordinate work',
];
const gridItems2 = [
  'ability to detach dispatch activity management from the sales process',
  'a custom workflow designed to conduct pre-shipment QA assessment and approvals',
  'operational system implemented to track business activities from RFQs to Delivery notes',
];

const CaseStudies = () => {
  const popup = () => {
    const popup1 = typeformEmbed.makePopup(
      'https://picortex.typeform.com/to/NBpm8D',
      {
        hideHeaders: true,
        hideFooter: true,
      }
    );
    popup1.open();
  };

  return (
    <PageLayout title="Massive Quantum Case study">
      <StyledSection>
        <StyledContainer>
          <div className="py-16 bg-gray-50 overflow-hidden">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <p className="text-base leading-6 text-primaryColor font-semibold tracking-wide uppercase">
                  Case Study
                </p>
                <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Massive Quantum
                </h1>
              </div>
              <div className="relative z-10 text-base max-w-prose mx-auto mb-8 lg:max-w-5xl lg:mx-0 lg:pr-72">
                <SubHeading
                  textAlign="left"
                  margin="0 0 40px 0"
                  fontWeight="light"
                  color="bodyColor"
                >
                  Massive Quantum (Pty) Ltd is a Level 1 B-BBEE, 100%
                  black-owned and 51% black-women owned South African based
                  company, and was established in November 2012 to fulfil the
                  production requirements of various industries in South Africa
                  and neighbouring states
                </SubHeading>
              </div>
              <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
                <div className="relative z-10 mb-12 lg:mb-0">
                  <div className="mb-10 prose text-gray-500 mx-auto lg:max-w-none">
                    <Text color="secondaryTextColorLight">
                      MQ was facing a significant challenge whereby they
                      struggled to collate sales data i.e. number of quotes the
                      organisation pushed out, sales team tracking of orders as
                      well as whether the order is still open or closed. This
                      had a negative impact on their business, as they were
                      unable to report back on accurate data annually when
                      reviewing sales stats, due to the manual process they had
                      in place (missing information)
                    </Text>
                    <GridContainer>
                      {gridItems.map((grid, index) => (
                        <Text className="mb-2" key={index}>
                          <span className="w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                            <XCircle color="#E53E3E" size="25" />
                          </span>
                          <span className="text-secondaryTextColorLight">
                            {grid}
                          </span>
                        </Text>
                      ))}
                    </GridContainer>
                    <Text color="secondaryTextColorLight">
                      MQ needed a solution-orientated, professional, and
                      insightful way to transpose their manual system into an
                      automated CRM system. They saw significant improvements
                      right away and are currently in full adoption.
                    </Text>
                    <div className="mt-10">
                      <SubHeading textAlign="left">Outcome</SubHeading>
                      <Text color="secondaryTextColorLight">
                        Using PiCortex’s CRM module, Massive Quantum was
                        immediately able to achieve the below;
                      </Text>
                      <GridContainer>
                        {gridItems2.map((grid, index) => (
                          <Text className="mb-2" key={index}>
                            <span className="w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                              <CheckCircle color="#45D68B" size="25" />
                            </span>
                            <span className="text-secondaryTextColorLight">
                              {grid}
                            </span>
                          </Text>
                        ))}
                      </GridContainer>
                    </div>
                  </div>
                  <div className="flex text-base max-w-prose mx-auto lg:max-w-none">
                    <div className="rounded-md shadow">
                      <a
                        className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:text-primaryColor bg-primaryColor hover:bg-white border-solid border-2 border-primaryColor hover:border-primaryColor focus:outline-none focus:border-primaryColor focus:shadow-outline-primaryColor transition duration-150 ease-in-out"
                        onClick={() => popup()}
                      >
                        Get started
                      </a>
                    </div>
                    <div className="rounded-md shadow ml-4">
                      <a
                        className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-primaryColor bg-white hover:text-green-600 focus:outline-none focus:border-primaryColor focus:shadow-outline-primaryColor transition duration-150 ease-in-out"
                        onClick={() => IntercomAPI('show')}
                      >
                        Contact sales
                      </a>
                    </div>
                  </div>
                </div>
                <div className="relative text-base max-w-prose mx-auto lg:max-w-none">
                  <svg
                    className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                    width="404"
                    height="384"
                    fill="none"
                    viewBox="0 0 404 384"
                  >
                    <defs>
                      <pattern
                        id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="384"
                      fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                    />
                  </svg>
                  <blockquote className="relative bg-white rounded-lg shadow-lg">
                    <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                      <img
                        src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1596731844/Partners/mq_color_tvp9tf.png"
                        alt="Massive Quantum"
                        className="object-contain h-32 w-32"
                      />
                      <div className="relative text-lg text-gray-700 leading-7 font-medium mt-8">
                        <svg
                          className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="relative">
                          The CRM system has been a success in our organisation
                          which has improved our operational culture. We have
                          certainly levelled up!
                        </p>
                      </div>
                    </div>
                    <cite className="flex items-center sm:items bg-primaryColor rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                      {/* <div className="rounded-full border-2 border-white mr-4 sm:-mt-15 sm:mr-6">
                        <img
                          className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300"
                          src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1599215201/PiCortex%20Website/Gerold_av9d99.jpg"
                          alt="Massive Quantum"
                        />
                      </div> */}
                      <span className="text-textColor font-semibold leading-6">
                        <strong className="text-white font-semibold">
                          Chantal Winnaar
                        </strong>
                        <br />
                        Director, Massive Quantum
                      </span>
                    </cite>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </StyledContainer>
      </StyledSection>
    </PageLayout>
  );
};
export default CaseStudies;
